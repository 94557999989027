<template>
  <div class="file_upLoad_Components">
    <el-upload
      class="file_upLoad"
      action="http://oss.aliyuncs.com"
      :show-file-list="false"
      list-type="picture"
      :on-exceed="handleExceed"
      :file-list="fileList"
      :on-change="fileChange"
      :auto-upload="false"
      :multiple="multiple"
      :accept="accept"
      :limit="limit"
      :disabled="isDisabled"
      ref="baseUpload"
    >
      <div>
        <slot></slot>
      </div>
    </el-upload>
    <el-dialog
      :title="$t('pictureCrop')"
      :visible.sync="dialogVisible"
      append-to-body
      :close-on-click-modal="false"
      width="890px"
      :destroy-on-close="true"
      :before-close="beforeClose"
    >
      <div class="clearfix" :style="{ visibility: visibilityStatus }">
        <div class="crop_content fl">
          <img :src="cropperImg" style="max-width: 100%" ref="img" />
        </div>
        <div class="fl preivew_content">
          <p class="text">{{ $t("preview") }}</p>
          <div id="previewCropper"></div>
          <div class="dialog-footer">
            <el-button @click="closeDialog" size="small">{{
              $t("cancel")
            }}</el-button>
            <el-button
              type="primary"
              @click="cropperFinish"
              :loading="croploading"
              size="small"
              >{{ $t("save") }}</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";
import { randomString, getFileType, getFileName } from "~/baseUtils";
import { MD5 } from "~/baseUtils/MD5";
import handleRelyConfig from "~/baseConfig/rely";
// import { configData, getVodData } from "~/baseConfig/rely";
export default {
  props: {
    needCropImg: {
      //是否需要图片裁剪
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      //图片裁剪比例
      default: 16 / 9,
    },
    multiple: {
      //多选
      type: Boolean,
      default: false,
    },

    accept: {
      //文件类型(小写格式 如：.jpg,.jpeg,.bmp,.png)
      type: String,
      default: "*",
    },
    autoUpload: {
      //是否直接上传阿里云
      type: Boolean,
      default: false,
    },
    companyId: {
      //公司ID,上传视频时，此参数必传
      default: null,
    },
    acceptSize: {
      //图片尺寸范围
      default: null,
    },
    limit: {
      //限制选择文件个数
      type: Number,
      default: null,
    },
    ossPath: {
      //文件路径
      type: String,
      default: "",
    },
    isDisabled: {
      //是否禁用
      type: Boolean,
      default: false,
    },

    limitSize: {
      //限制文件大小尺寸，以MB为单位
      type: Number,
      default: 0,
    },
    isSingleVideo: {
      //是否为单视频上传
      type: Boolean,
      default: false,
    },
    isTranscode: {
      //是否转码
      type: Boolean,
      default: true,
    },
    isUserVideo: {
      //是公司视频还是个人视频1公司2个人
      type: Number,
      default: 1,
    },
  },
  name: "",
  data() {
    return {
      cropperImg: "",
      actionAddress: "",
      fileList: [],
      dialogVisible: false,
      croploading: false,
      fileType: "",
      visibilityStatus: "visible",
      ossData: null,
      //ossPath: "images/",
      company_id: null,
      aladyNum: 0,
      isChina: false,
      ympOssData:{
        accessKeyId: "LTAI4G8UAnekcq6V9NLyHRz3",
        accessKeySecret: "lE5Hv8pXyxjGucWKz6a4JF1hS5gpY2",
        bucket: "ym-attached-file",
        region: "oss-cn-shanghai",
      },
    };
  },
  mounted() {
    if (this.PJID === 'YMP') {
      this.ossData = Object.assign({}, this.ympOssData)
    } else {
      this.ossData = handleRelyConfig.ossData
    }
    this.initCompanyId();
  },
  methods: {
    initCompanyId() {
      this.company_id = this.companyId
        ? this.companyId
        : this.$moment().format("MM-DD");
    },
    beforeClose(done) {
      this.visibilityStatus = "hidden";
      done();
    },
    closeDialog() {
      this.visibilityStatus = "hidden";
      this.dialogVisible = false;
    },
    clearFileList() {
      this.fileList = [];
      this.aladyNum = 0;
      this.$refs.baseUpload.clearFiles();
    },
    uploadFileToOss() {
      this.$emit("startUploadOSS");
      //上传文件到OSS（图片,文件）
      this.fileList.forEach((item) => {
        this.handleUploadOss(
          item.raw,
          (result) => {
            this.aladyNum++;
            if (this.aladyNum == this.fileList.length) {
              this.clearFileList();
            }
            this.$emit("uploadCompleteOSS", result);
          },
          {
            fileName: MD5(`${this.company_id}+${getFileName(item.name)}`),
            fileType: getFileType(item.name),
          }
        );
      });
    },
    cropperFinish() {
      //裁剪完成
      this.croploading = true;
      this.cropper.getCroppedCanvas().toBlob(async (data) => {
        this.handleUploadOss(
          data,
          (result) => {
            this.croploading = false;
            this.dialogVisible = false;
            this.cropper = null;
            this.clearFileList();
            this.$emit("uploadCompleteOSS", result);
          },
          {
            fileName: MD5(
              `${this.company_id}+${getFileName(this.fileList[0].name)}`
            ),
            fileType: "PNG",
          }
        );
      });
    },
    handleUploadOss(data, nv, options = {}) {
      //oss上传方法

      let ossData = this.ossData;
      let ossPath = this.confirmOssPath(data);
      const reader = new FileReader();
      reader.readAsArrayBuffer(data);
      reader.onload = (event) => {
        const client = new OSS.Wrapper(ossData);
        const objectKey = `${ossPath}${
          options.fileName
        }.${options.fileType.toLowerCase()}`;
        const buffer = new OSS.Buffer(event.target.result);
        client
          .put(objectKey, buffer)
          .then((result) => {
            nv && nv(result);
          })
          .catch((err) => {});
      };
    },
    confirmOssPath(data) {
      if (this.ossPath) {
        return this.ossPath;
      }
      let type = "";
      if (data.name) {
        type = getFileType(data.name).toLowerCase();
      } else {
        type = data.type.indexOf("image") != -1 ? "png" : "other";
      }
      let imageType = ["jpg", "jpeg", "bmp", "png"];
      let ossPath =
        imageType.indexOf(type) != -1 ? "userImages/" : "userFiles/";
      return ossPath;
    },
    deleteOssItem(name) {
      //删除阿里云oss文件
      if (!name) return;
      const client = new OSS.Wrapper(this.ossData);
      client.delete(name);
    },
    async fileChange(file, fileList) {
      let type = getFileType(file.name);
      this.$emit("getFileOriginalName", file.name);
      if (
        this.accept != "*" &&
        this.accept
          .split(",")
          .map((item) => {
            return item.trim();
          })
          .indexOf(`.${type.toLowerCase()}`) == -1
      ) {
        fileList.pop();
        this.$message.warning(this.$t("formatError"));
        return;
      }

      if (this.limitSize) {
        let limit = this.limitSize * 1024 * 1024;
        if (file.size > limit) {
          fileList.pop();
          this.$message.warning(
            this.$t("fileSizeTooBig", { size: `${this.limitSize}MB` })
          );
          return;
        }
      }
      if (this.acceptSize) {
        let minWidth = parseInt(this.acceptSize.width.split("-")[0]);
        let maxWidth = parseInt(this.acceptSize.width.split("-")[1]);
        let minHeight = parseInt(this.acceptSize.height.split("-")[0]);
        let maxHeight = parseInt(this.acceptSize.height.split("-")[1]);
        let imageSize = await this.getImageSize(file);

        if (
          imageSize.width < minWidth ||
          imageSize.width > maxWidth ||
          imageSize.height < minHeight ||
          imageSize.height > maxHeight
        ) {
          fileList.pop();
          this.$message.warning(this.$t("sizeError"));
          return;
        }
      }
      //选择文件
      this.fileList = fileList;
      if (this.needCropImg) {
        this.$nextTick(() => {
          this.cropperImg = file.url;
          this.visibilityStatus = "visible";
          this.dialogVisible = true;
          this.$nextTick(() => {
            let cropper = new Cropper(this.$refs.img, {
              viewMode: 0,
              aspectRatio: this.aspectRatio,
              dragMode: "move",
              preview: "#previewCropper",
              outputSize: "0.8",
            });
            this.cropper = cropper;
          });
        });
      } else if (this.autoUpload) {
        this.uploadFileToOss();
      } else if (this.isSingleVideo) {
        this.uploadVideoToVod();
      } else {
        this.$emit("selectFile", fileList);
      }
    },
    getImageSize(file) {
      return new Promise((resolve, reject) => {
        let img = new Image();
        img.src = file.url;
        img.onload = function () {
          let obj = {
            width: img.width,
            height: img.height,
          };
          resolve(obj);
        };
      });
    },
    handleExceed(files, fileList) {
      this.$message.warning(this.$t("uploadMax", { limit: this.limit }));
    },
    uploadVideoToVod() {
      this.$emit("startUpdateVideo");
      //上传视频到VOD
      let userData = '{"Vod":{}}';
      this.fileList.forEach((item, index) => {
        setTimeout(() => {
          let _uploader = this.createVideoUploader();
          _uploader.addFile(item.raw, null, null, null, userData);
          _uploader.startUpload();
        }, index * 2000);
      });
    },
    createVideoUploader() {
      let uploader = new AliyunUpload.Vod({
        timeout: 60000,
        partSize: 1048576 * 5,
        parallel: 3,
        retryCount: 3,
        retryDuration: 2,
        region: handleRelyConfig.vodData.region,
        userId: "1657172572736971",
        // 添加文件成功
        addFileSuccess: (uploadInfo) => {},
        // 开始上传
        onUploadstarted: async (uploadInfo) => {
          try {
            let result = await this.$store.dispatch(
              "baseStore/sms_getUploadVideoToken",
              {
                company_id: this.company_id,
                video_name: getFileName(uploadInfo.file.name),
                file_name:
                  MD5(
                    `${this.company_id}+${getFileName(uploadInfo.file.name)}`
                  ) + `.${getFileType(uploadInfo.file.name)}`,
                video_type: 1,
                region: handleRelyConfig.vodData.region,
                user_id: this.USER_INFO.id,
                video_kind: this.isUserVideo,
                is_transcode:  0,
              }
            );
            if (result.success) {
              uploader.setUploadAuthAndAddress(
                uploadInfo,
                result.data.UploadAuth,
                result.data.UploadAddress,
                result.data.VideoId
              );
            } else {
              this.$seTip();
            }
          } catch (e) {
            console.log(e);
            this.$seTip();
          }
        },
        // 文件上传成功
        onUploadSucceed: async (uploadInfo) => {
          this.setVideoCover(uploadInfo.videoId);
          this.$emit("uploadCompleteVOD", uploadInfo);
          if (this.isSingleVideo) {
            this.fileList = [];
            //this.setVideoCover(uploadInfo.videoId);
          }
        },
        // 文件上传失败
        onUploadFailed: (uploadInfo, code, message) => {},
        // 取消文件上传
        onUploadCanceled: (uploadInfo, code, message) => {},
        // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
        onUploadProgress: (uploadInfo, totalSize, progress) => {
          this.$emit("uploadProgressVOD", {
            name: uploadInfo.file.name,
            progress: progress * 100,
          });
        },
        // 上传凭证超时
        onUploadTokenExpired: (uploadInfo) => {},
        // 全部文件上传结束
        onUploadEnd: (uploadInfo) => {},
      });
      return uploader;
    },
    async setVideoCover(aliyun_id) {
      let result = await this.$store.dispatch("baseStore/getVideoPlayUrl", {
        aliyun_id: aliyun_id,
      });
      if (result.success) {
        let url = result.data.play_list.find((item) => {
          return (
            item.definition == "OD" ||
            item.definition == "LD" ||
            item.definition == "SD"
          );
        }).url;
        let cover_url = `${url}?x-oss-process=video/snapshot,t_0`;
        let coverResult = await this.$store.dispatch(
          "baseStore/setupVideoCover",
          {
            aliyun_id: aliyun_id,
            cover_url,
          }
        );
        if (coverResult.success) {
          let infoResult = await this.$store.dispatch("baseStore/getVideoInfoByAliyunId",{
            aliyun_id
          });
          if(infoResult.success){
            this.$emit("VODcallbackComplete", infoResult.data);
          }   
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.clearfix {
  height: 400px;
  .crop_content {
    width: 500px;
    height: 400px;
    border: dashed #cacaca 1px;
    text-align: center;
  }
  .preivew_content {
    width: 320px;
    height: 100%;
    position: relative;
    margin-left: 10px;
    .text {
      font-size: 16px;
      margin-bottom: 15px;
    }
    #previewCropper {
      border: dashed #cacaca 1px;
      margin: 0 auto;
      width: 320px;
      height: 320px;
      overflow: hidden;
    }
    .dialog-footer {
      position: absolute;
      width: 100%;
      bottom: 0px;
    }
  }
}
</style>
